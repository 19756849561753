/* eslint-disable no-undef */

let selectDefaultDepartment;
if (typeof selectDefaultDepartment === 'undefined') {
  selectDefaultDepartment = (department) => {
    zE('webWidget', 'updateSettings', {
      webWidget: {
        chat: {
          departments: {
            // Masque l'option de liste déroulante du formulaire pré-chat
            // qui permet de sélectionner un service
            enabled: [''],
            // Définit automatiquement le service
            select: department,
          },
          suppress: false,
        },
      },
    });
  };
}

document.addEventListener('DOMContentLoaded', () => {
  zE('webWidget:on', 'chat:connected', () => {
    const department = zE('webWidget:get', 'chat:department', 'Commercial');
    if (department && department.name === 'Commercial') {
      selectDefaultDepartment('Commercial');
      if (department.status === 'online' || department.status === 'away') {
        // Le service est en ligne ou away (au moins un agent de ce service est en ligne ou absent)
        // Ajoute automatiquement un marqueur
        zE('webWidget', 'chat:addTags', ['chat_commercial']);
      }
      if (department.status === 'offline') {
        // Masque le chat si le service est offline
        zE('webWidget', 'updateSettings', { webWidget: { chat: { suppress: true } } });
      }
    }
  });
});

/* eslint-enable no-undef */
